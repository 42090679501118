import * as React from "react"
import { useRef, useState, useEffect } from "react"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import { useWindowScroll } from "react-use"
import cx from "classnames"

import Section from "@components/elements/section"
import LinesGap from "@components/elements/linesGap"

import styles from "./timeline.module.scss"

interface TimelineProps {
  title?: string
  text?: string
  items: Array<{
    title: string
    text: string
    image: ImageDataLike
    imageInactive: ImageDataLike
  }>
}

const Timeline = ({ title, text, items }: TimelineProps) => {
  const [timelineItems] = useState(
    items.map(item => {
      const image = getImage(item.image)
      const imageInactive = getImage(item.imageInactive)

      return {
        ...item,
        image: image,
        imageInactive: imageInactive,
      }
    })
  )
  const sectionRef = useRef()

  return (
    <>
      <Section className={styles.timeline} ref={sectionRef}>
        {title && <LinesGap title={title} text={text} variant="primary" />}

        <div className={styles.listContainer}>
          <ol className={styles.list}>
            {timelineItems.map((item, index) => {
              const isActive = true;
              return (
                <li
                  className={cx(styles.item, {
                    [styles.isActive]: isActive,
                  })}
                  key={item.title}
                >
                  <div className={styles.itemTitle}>{item.title}</div>
                  {timelineItems.slice(0, index).map(previousItem => (
                    <GatsbyImage
                      key={previousItem.title}
                      className={cx(styles.itemPicture, styles.isInactive)}
                      image={previousItem.imageInactive}
                      objectFit="contain"
                      alt=""
                    />
                  ))}
                  <GatsbyImage
                    className={styles.itemPicture}
                    image={item.image}
                    objectFit="contain"
                    alt=""
                  />
                  <div className={styles.itemText}>{item.text}</div>
                </li>
              )
            })}
          </ol>
        </div>
      </Section>
    </>
  )
}

export default Timeline

import * as React from "react"
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider"
import cx from "classnames"

import Section from "@components/elements/section"
import LinesGap from "@components/elements/linesGap"
import Wysiwyg from "@components/elements/wysiwyg"

import platformSrc from "@images/platform-chart.jpg"
import originalSrc from "@images/original-chart.jpg"
import HandlerIcon from "@images/handler-icon.inline.svg"

import styles from "./compare.module.scss"

interface CompareProps {
  title?: string
  text?: string
  note?: string
  noteMobile?: string
}

const SLIDER_INIT_POSITION = 34

const DATA_ITEMS = [
  {
    src: platformSrc,
    alt: "With Avalo Platform Chart",
    title: "With Avalo Platform",
  },
  {
    src: originalSrc,
    alt: "Current Standard Chart",
    title: "Current Standard",
  },
]

const Compare = ({ title, text, note, noteMobile }: CompareProps) => {
  return (
    <>
      <Section className={styles.compare}>
        {title && <LinesGap title={title} text={text} variant="primary" />}

        <div className={styles.scrollContainer}>
          {DATA_ITEMS.map(item => (
            <div className={styles.scrollItem} key={item.title}>
              <img
                className={styles.scrollItemPicture}
                src={item.src}
                alt={item.alt}
              />
              <div className={styles.scrollItemTitle}>{item.title}</div>
            </div>
          ))}
        </div>

        <div className={styles.sliderContainer}>
          <ReactCompareSlider
            className={styles.slider}
            position={SLIDER_INIT_POSITION}
            handle={
              <div className={styles.sliderBorder}>
                <button
                  className={styles.sliderHandle}
                  aria-label="Drag to see the picture"
                >
                  <HandlerIcon className={styles.sliderHandleIcon} />
                </button>
              </div>
            }
            itemOne={
              <ReactCompareSliderImage
                src={DATA_ITEMS[0].src}
                alt={DATA_ITEMS[0].alt}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={DATA_ITEMS[1].src}
                alt={DATA_ITEMS[1].alt}
              />
            }
          />
          <div className={cx(styles.sliderTitle, styles.left)}>
            {DATA_ITEMS[0].title}
          </div>
          <div className={cx(styles.sliderTitle, styles.right)}>
            {DATA_ITEMS[1].title}
          </div>
        </div>

        {noteMobile && (
          <Wysiwyg
            className={cx(styles.note, styles.isMobile)}
            contentHtml={noteMobile}
          />
        )}
        {note && <Wysiwyg className={styles.note} contentHtml={note} />}
      </Section>
    </>
  )
}

export default Compare

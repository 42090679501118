import * as React from "react"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"

import Section from "@components/elements/section"
import ScrollTrigger from "@components/elements/scrollTrigger"
import { CtaContainer, CtaProps } from "@components/elements/cta"

import styles from "./grid.module.scss"

interface GridProps {
  gridTitle?: string
  gridItems: Array<{
    title: string
    text?: string
    image: ImageDataLike
  }>
  listTitle?: string
  listItems: Array<{
    title: string
  }>
  bottomTitle?: string
  cta: CtaProps
}

const Grid = ({
  gridTitle,
  gridItems,
  listTitle,
  listItems,
  bottomTitle,
  cta,
}: GridProps) => (
  <Section className={styles.gridSection} containerClassName={styles.container}>
    {gridTitle && <h2 className={styles.gridTitle}>{gridTitle}</h2>}
    <div className={styles.grid}>
      {gridItems.map(item => {
        const image = getImage(item.image)

        return (
          <div key={item.title} className={styles.gridItem}>
            <ScrollTrigger className={styles.itemPicture}>
              <GatsbyImage image={image} objectFit="contain" alt="" />
            </ScrollTrigger>
            <h3 className={styles.itemTitle}>{item.title}</h3>
            {item.text && <div className={styles.itemText}>{item.text}</div>}
          </div>
        )
      })}
    </div>

    {listTitle && <h3 className={styles.listTitle}>{listTitle}</h3>}
    <div className={styles.list}>
      {listItems.map(item => (
        <h4 key={item.title} className={styles.listItem}>
          {item.title}
        </h4>
      ))}
    </div>

    {bottomTitle && <h3 className={styles.bottomTitle}>{bottomTitle}</h3>}
    <CtaContainer {...cta} className={styles.buttonCta} />
  </Section>
)

export default Grid

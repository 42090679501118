import * as React from "react"
import { useRef } from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout/layout"
import TechnologyHero from "@components/technologyHero"
import Timeline from "@components/timeline"
import Grid from "@components/grid"
import Compare from "@components/compare"
import DetailsList from "@components/detailsList"
import CtaSection from "@components/ctaSection"

import { scrollToElement } from "@lib/utils"

const Technology = ({ data }: any) => {
  const {
    heroData,
    timelineData,
    gridData,
    compareData,
    detailsListData,
    ctaSectionData,
  } = data.pageData
  const nextSectionRef = useRef(null)
  const scrollToNextSection = () => scrollToElement(nextSectionRef.current)

  return (
    <Layout title="Technology" description="">
      <TechnologyHero {...heroData} scrollToNextSection={scrollToNextSection} />
      <div ref={nextSectionRef} />
      <Timeline {...timelineData} />
      <Grid {...gridData} />
      <Compare {...compareData} />
      <DetailsList {...detailsListData} variant="centered" bigTitle />
      <CtaSection {...ctaSectionData} />
    </Layout>
  )
}

export default Technology

export const pageQuery = graphql`
  query {
    pageData: technologyJson {
      heroData: hero {
        items {
          intro
          title
          text
          textMobile
          caption
        }
      }
      timelineData: timeline {
        title
        text
        items {
          title
          text
          image: imageUrl {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          imageInactive: imageInactiveUrl {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      gridData: grid {
        gridTitle
        gridItems {
          title
          text
          image: imageUrl {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        listTitle
        listItems {
          title
        }
        bottomTitle
        cta {
          text
          url
        }
      }
      compareData: compare {
        title
        text
        note
        noteMobile
      }
      detailsListData: detailsList {
        title
        items {
          title
          text
          image: imageUrl {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ctaSectionData: ctaSection {
        title
        cta {
          text
          url
        }
      }
    }
  }
`
